<template>
  <div class="collection-exchange-skuNo-container">
    <div class="collection-exchange-title">
      <div
        @click="goBack()"
        class="return-button"
      ></div>
      <img
        class="return-img"
        @click="goBack()"
        src="../../assets/icon/return.png"
      />
      <p>选择藏品</p>
    </div>
    <!-- 兑换所需藏品 -->
    <div class="collection-exchange-content">
      <div
        class="collection-exchange-content-item row-between"
        v-for="(item,index) in userPlanAssetsList"
        :key="index"
        @click="(val) => checkboxChange(val, item, index)"
      >
        <div class="row-center">
          <div class="collection-exchange-item-img-bg row-center">
            <img :src="item.image" />
          </div>
          <div>
            <p class="collection-exchange-name">{{item.name}}</p>
            <p class="collection-exchange-amount">{{ item.showId }}</p>
          </div>
        </div>
        <div class="row-center collection-exchange-right">
          <img
            v-if="item.checked"
            src="../../assets/icon/gashapon/select.png"
          />
          <img
            v-if="!item.checked"
            src="../../assets/icon/gashapon/no-select.png"
          />
        </div>
      </div>
    </div>
    <div
      style="z-index:1999;"
      class="btns row-center"
    >
      <p class="need-amount">共需{{needAmount * value}}份</p>
      <div
        class="comfirm-submit"
        @click="confirmComposite"
      ></div>
    </div>
  </div>
</template>
<script>
import api from '../../api/index-client'

export default {
  data () {
    return {
      collectionExchangeInfo: {},
      userPlanAssetsList: [],
      submitAssetsList: {},
      activeUnicode: [],
      value: 0,
      needAmount: null
    }
  },
  watch: {
  },
  mounted () {
    this.collectionExchangeInfo = JSON.parse(localStorage.getItem('collectionExchangeItem'))
    this.value = Number(sessionStorage.getItem('value'))
    this.submitAssetsList = JSON.parse(localStorage.getItem('submitAssetsList'))
    this.activeUnicode = this.submitAssetsList[this.$route.query.skuNo]
    console.log(this.submitAssetsList);
    this.needAmount = this.collectionExchangeInfo.skuList.filter(i => i.skuNo == this.$route.query.skuNo)[0].amount
    this.getUserPlanAssets()
  },
  methods: {
    // 获取用户资产列表
    getUserPlanAssets () {
      api
        .get('lottery/exchange/getUserPlanAssets?planId=' + this.collectionExchangeInfo.id)
        .then(result => {
          if (result.data.success) {
            this.userPlanAssetsList = result.data.data[this.$route.query.skuNo]
            this.activeMethod()
            console.log(this.userPlanAssetsList);
            this.$store.commit('HIDE_APPLOADING')
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 筛选已选择
    activeMethod () {
      this.userPlanAssetsList.map(i => {
        if (this.activeUnicode.filter(j => j == i.uniqueCode).length) {
          i.checked = true
        } else {
          i.checked = false
        }
      })
    },
    confirmComposite () {
      console.log(this.userPlanAssetsList.filter(i => i.checked));
      if ((this.userPlanAssetsList.filter(i => i.checked).length < this.needAmount * this.value)) {
        return this.$toast({
          message: '所选藏品数量不足',
          icon: require('../../assets/icon/toast-error.png'),
        });
      } else {
        this.activeUnicode = []
        this.userPlanAssetsList.filter(i => i.checked).map(i => {
          this.activeUnicode.push(i.uniqueCode)
        })
        this.submitAssetsList[this.$route.query.skuNo] = this.activeUnicode
        localStorage.setItem('submitAssetsList',JSON.stringify(this.submitAssetsList))
        this.$router.replace('/collectionExchangeDetail?lotteryCode=' + this.$route.query.lotteryCode)
      }
    },
    goBack () {
      this.$router.replace('/collectionExchangeDetail?lotteryCode=' + this.$route.query.lotteryCode)
    },
    checkboxChange (val,item,index) {
      if ((this.userPlanAssetsList.filter(i => i.checked).length >= this.needAmount * this.value) && !item.checked) {
        return this.$toast({
          message: '所选藏品数量已超过所需材料数量',
          icon: require('../../assets/icon/toast-error.png'),
        });
      } else {
        item.checked = !item.checked
        this.$forceUpdate()

      }
    },
  },
}
</script>
<style lang="less" scoped>
.collection-exchange-skuNo-container {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  position: absolute;
  top: 0;
  background: #fffaf4;
  .collection-exchange-title {
    width: 100vw;
    height: 4rem;
    font-size: 1.125rem;
    font-weight: 900;
    color: #1c1c1c;
    line-height: 1.5625rem;
    background: #fffaf4;
    position: fixed;
    top: 0;
    p {
      width: 100%;
      text-align: center;
      margin-top: 6%;
    }
  }
  .lottery-size-bg {
    width: 92vw;
    height: 22.4vw;
    margin: 30px 3vw 0 3vw;
    background: #f3ddd4;
    border-radius: 10px;
    .lottery-size-title {
      font-size: 12px;
      font-weight: 500;
      color: #080808;
    }
    .lottery-size {
      font-size: 27px;
      margin-top: 8px;
      font-weight: bold;
      color: #080808;
    }
  }
  .collection-exchange-next {
    width: 9vw;
    height: 9vw;
    margin: 15px 45.5vw 16px 45.5vw;
  }
  .collection-exchange-content {
    width: 92vw;
    height: auto;
    margin: 4vw;
    padding: 10px 0;
    background: #f3ddd4;
    border-radius: 10px;
    margin-bottom: 71px;
    margin-top: 62px;
    .collection-exchange-content-item {
      width: 86vw;
      height: 21.34vw;
      margin-left: 3vw;
      background: #de8466;
      margin-bottom: 10px;
      border-radius: 10px;
      .collection-exchange-item-img-bg {
        width: 17vw;
        height: 17vw;
        background-image: url('../../assets/icon/gashapon/collection-exchange-item-img-bg.png');
        background-size: 100% auto;
        background-repeat: no-repeat;
        margin-right: 9px;
        margin-left: 3vw;
        img {
          width: 14vw;
          height: 14vw;
        }
      }
      .collection-exchange-name {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
      }
      .collection-exchange-amount {
        font-size: 12px;
        font-weight: 500;
        margin-top: 10px;
        color: #ffffff;
      }
    }
    .collection-exchange-right {
      img {
        width: 18px;
        height: auto;
        margin: 0 14px;
      }
    }
    .collection-exchange-content-item:last-child {
      margin-bottom: 0px;
    }
  }
}
.btns {
  // width: 375px;
  height: 4rem;
  background: #fffaf4;
  padding-top: 0.4375rem;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  .need-amount {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #a4a4a4;
    margin-right: 20px;
  }
  .comfirm-submit {
    width: 60vw;
    height: 16vw;
    background-image: url('../../assets/icon/gashapon/comfirm-submit.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
  .comfirm-submit:active {
    opacity: 0.6;
  }
}
.return-button {
  width: 2.5rem;
  height: 2.5rem;
  background: #000000;
  border-radius: 50%;
  z-index: 2001;
  opacity: 0.2;
  position: fixed;
  top: 1rem;
  left: 1rem;
  filter: alpha(opacity=20);
}
.return-img {
  width: 0.625rem;
  height: auto;
  position: fixed;
  top: 1.75rem;
  left: 2rem;
  z-index: 2002;
}
</style>